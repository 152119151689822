import "./App.css";

function App() {
  return (
    <div className="App">
      <header></header>
      <body className="App-header">
        <img src={"icon_name.png"} style={{ width: 700, height: 700 }} />
        <p>SITE UNDER CONSTRUCTION</p>
        <a
          className="App-link"
          href="https://www.instagram.com/hazartconcept/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Check our Instagram Page!
        </a>
      </body>
    </div>
  );
}

export default App;
